<template>
    <div ref="lfTb">
        <Table ref="lftable" :row-key="rowKey" :highlight-row="highlightRow" @on-current-change="currentChangeFn"
            :border="tableData && tableData.length" stripe :height="tableHeight" :columns="talbeColumns" :loading="loading"
            :data="tableData" @on-selection-change="tableSelect" @on-sort-change="tableSort" :show-summary="showsummary"
            :summary-method="summaryData != null && summaryData != {} ? handleSummary : null" :span-method="handleSpan">
        </Table>
        <Page v-if="!hidePage" style="float:right;margin:10px 10px 0 0;" @on-change="currentPage" :current="pageParams.currentPage"
            @on-page-size-change="hadlePageSize" :page-size="pageParams.pageSize" :total="pageParams.maxCount"
            :page-size-opts="pagesizeOpts || pagesizeOptsDF" size="small" show-total show-elevator show-sizer />
    </div>
</template>

<script>

export default {
    name: "fnctable",
    props: {
        tableData: Array, //传入的数据集
        talbeColumns: Array,//传入的表头集
        loading: Boolean,//传入的加载等待开关
        height: String,//表格高度
        fixTable: Boolean,//满屏固定表格，自定义height将无效

        pageParams: {
            type: Object, default: () => {
                return {
                    maxCount: 0,//数据总条数
                    currentPage: 1,//当前页(默认第一页)
                    pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
                    maxPage: 1,//总页数
                    sort: "id",//排序字段
                    order: "desc",//排序方式
                    keyword: "",//通用查询条件
                    keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
                }
            }
        },
        pagesizeOpts: Array,//分页数组
        hidePage: Boolean,//是否隐藏分页
        highlightRow: { type: Boolean, default: false },//选中单行
        showsummary: { type: Boolean, default: false },//显示合计行
        summaryData: { type: Object, default: () => { return {} } },//指定的合计数据
        currentChange: { type: Object, default: () => { return {} } },//选中单行触发
        // 合并行列方法，父组件必须绑定handleSpanFather
        handleSpanFather: {
            type: Function,
            default: null
        },
        rowKey:"",
    },
    components: {
    },
    data() {
        return {
            tableHeight: '',
            selection: [],
            delData: {},
            pSize: 0,
            pagesizeOptsDF: [20, 30, 50, 100, 200],//分页数组
        };
    },
    created() {
        //初始化值
        if (this.showsummary) {
            this.tableHeight = this.height - 50;
        } else {
            this.tableHeight = this.height;
        }

        this.pSize = this.pageSize == undefined || this.pageSize < 1 ? 10 : this.pageSize;
    },
    mounted() {
        var that = this;
        //  this.tableHeight=this.height;
        if (this.fixTable) {//自动满屏
            this.$(window).resize(this.resize);
            this.resize();
        }
    },
    methods: {
        resize() {
            this.tableHeight = window.innerHeight - (this.$refs.lftable ? this.$refs.lftable.$el.offsetTop : 10) - 102;
            if (this.showsummary) {
                this.tableHeight = this.tableHeight - 50;
            }
        },
        hadlePageSize(val) {//改变每页条数
            this.pageParams.pageSize = val;
            this.pageParams.currentPage = 1;
            this.$emit('handleLoadData', 1)
        },
        currentPage(val) {
            this.pageParams.currentPage = val;
            this.$emit('handleLoadData', val)
        },

        tableSort(sortdata) {
            this.$emit("tableSort", sortdata);
        },
        tableSelect(selection) {
            this.selection = selection;
            this.$emit("tableSelect", selection);
        },
        getSelection() {
            return this.$refs.lftable.getSelection();
        },
        handleSummary({ columns, data }) {
            return this.summaryData;
        },
        currentChangeFn(newRow, oldRow) {
            this.$emit("currentChange", newRow, oldRow);
        },
        handleSpan(val) {
            if (this.handleSpanFather) {
                return this.handleSpanFather(val)
            }
        }
    },
};
</script>

<style lang="less">
.ivu-table-overflowY::-webkit-scrollbar,.ivu-table-overflowX::-webkit-scrollbar {
    width: 8px;
    height: 11px;
}
</style>
