<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <div style="width:400px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入标题关键字" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>
      <Button type="info" class="addDiv" @click="() => { editData() }">
        <Icon type="md-add-circle" size="18" />添加
      </Button>
      <Button type="success" class="passedDiv" @click="() => { passedData() }">
        <Icon type="ios-eye-off" size="18" />启禁用
      </Button>
      <Button type="warning" class="deleteDiv" @click="() => { deleteData() }">
        <Icon type="md-trash" size="18" />删除
      </Button>

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="编辑窗" :fullscreen="false" width="900px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/shortvideo/list
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "短视频列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
      {
                    type: "selection",
                    width: 60,
                    fixed: "left",
                    align: "center",
                },
        {
          title: "图片标题",
          key: "poster",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h(
              "img",
              {
                style: {
                  maxWidth: "100%",
                  maxHeight: "100%",
                  textAlign: "center",
                  overflow: "hidden"
                },
                attrs: {
                  src: params.row.poster || "./images/nopic.png"
                }
              },
            )
          },
        },
        {
          title: "标题",
          key: "title",
          align: "left",
          minWidth: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    color: "#2b85e4",
                  },
                  domProps: {
                    title: params.row.title,
                  },
                  on: {
                    click: (e) => {
                      this.editData(params.row.id);
                      e.stopPropagation();
                    },
                  },
                },
                params.row.title
              ),
            ]);
          },
        },
     
        {
          title: "剧集数",
          key: "episodeTotal",
          align: "center",
          width: 100,
        },
        {
          title: "购买收看",
          key: "buyStart",
          align: "center",
          width: 120,
          render:(h,params)=>{
            return h("span",params.row.buyStart>0?"收费":"免费")
          }
        },
        {
          title: "扣积分",
          key: "price",
          align: "center",
          width: 150,
        },
        {
          title: "发布时间",
          key: "createTime",
          align: "center",
          width: 180,
        },
        {
          title: "启禁用",
          key: "idisplay",
          align: "center",
          fixed: "right",
          width: 80,
          render: (h, params) => {
            return h("div",
              {
                class: "passedDiv"
              },
              [
                h(
                  "i-switch",
                  {
                    props: {
                      value: params.row.idisplay == 1 ? true : false,
                      "before-change": () => {
                        return new Promise((resolve) => {
                          this.passedData(params.row, resolve);
                        });
                      }
                    },
                    on: {
                      "on-change": (e) => {
                        this.tableData[params.index].idisplay = e ? 1 : 0;
                      }
                    },
                  }, [
                  h('span', { slot: 'open' }, '启'),
                  h('span', { slot: 'close' }, '禁'),
                ]
                ),

              ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 140,
          fixed: "right",
          align: "center",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.id);
                      },
                    },
                  },
                  "修改"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.deleteData(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
    };
  },
  created() {

  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
    async loadModuleInfo() {
      //读取栏目信息
       this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
     
      //读取数据列表
      this.loadData(1);
      this.$core.checkControlButtonRight(
        [{ btn: this.$(".editDiv"), right: "E" },
        { btn: this.$(".deleteDiv"), right: "D" },
        { btn: this.$(".addDiv"), right: "A" },
        { btn: this.$(".passedDiv"), right: "P" }
        ]
      ).then(res => {
        this.right = res;
      });
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/shortvideo/api/shortvideo/list", 
        data: { 
          functionId: this.$core.getFunctionId(),
           ...this.pageParams 
          },
         success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = res.pageParams
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    passedData(row, resolve) {
      if (this.right["P"] == false) {
        this.$Message.error({ background: true, content: "你没有启禁用权限" })
        return;
      }
      var ids=""
      if(row){
        ids=row.id
      }else{
        let selectedList = this.$refs.fncTable.getSelection();
       if(selectedList&&selectedList.length>0){
        ids=selectedList.map(item=>{
          return item.id;
        }).join(",")
        }
      }
      if(!ids){
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }
      this.$Modal.confirm({
        title: '启禁确认',
        content: row?'您确认要<span style="color:red"> ' + (row.idisplay == 1 ? '禁用' : '启用') + " </span>“" + row.title + '”吗？':"确认要禁用/启用选中吗？",
        onOk: () => {
          this.$post({
            url: "/shortvideo/api/shortvideo/passed", data: { ids: ids },
            success: (res) => {
              if (res.code == "200") {
                if (resolve) resolve();
                this.$Message.success({ background: true, content: "审核成功" })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    deleteData(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      var ids=""
      if(row){
        ids=row.id
      }else{
        let selectedList = this.$refs.fncTable.getSelection();
       if(selectedList&&selectedList.length>0){
        ids=selectedList.map(item=>{
          return item.id;
        }).join(",")
        }
      }
      if(!ids){
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }

      this.$Modal.confirm({
        title: '删除确认',
        content: row?'您确认要删除“<span style="color:red"> ' + row.title + '</span>”吗？':"确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/shortvideo/api/shortvideo/delete", data: { ids: ids},
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: "删除完成"})
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    }
  }
};
</script>
    
<style scoped lang='less'></style>