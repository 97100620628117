import axios from "axios";
import qs from "qs";
import proxy from "@/api/proxy.js";
import core from "@/utils/core.js";
// 创建取消请求
const CancelToken = axios.CancelToken
const source = CancelToken.source()
var cancelList = []
var removePending = () => {
    if (cancelList && cancelList.length) {
        cancelList.map(item => {
            if (item.source) {
                item.source.cancel()
            }
        })
    }
}
export function getProxy(target) {
    let url = proxy[target]
    if (url) url = url.target
    return url;
}
export async function get(option) {
    if (!option.header) option.header = { "Content-Type": "application/json" }
    if (!option.header["Content-Type"]) {
        option.header["Content-Type"] = "application/json";
    }
    var config = {
        method: "get",
        url: option.url,
        headers: option.header,
        params: option.data,
    };
    if (option.responseType) config.responseType = option.responseType;
    return await request(config, option.success, option.complete, option.fail);

}
export async function post(option) {
    if (!option.header) option.header = { "Content-Type": "application/x-www-form-urlencoded" }
    if (!option.header["Content-Type"]) {
        option.header["Content-Type"] = "application/x-www-form-urlencoded";
    }
    var config = {
        method: "post",
        url: option.url,
        headers: option.header,
        data: option.header['Content-Type'] == 'application/x-www-form-urlencoded' ? qs.stringify(option.data) : option.data,
    };
    if (option.responseType) config.responseType = option.responseType;
    return await request(config, option.success, option.complete, option.fail);
}

function request(config, success, complete, fail) {
    return new Promise((resolve) => {
        axios(config).then(
            async (response) => {
                var rs = response;
                if (response && response.data && response.data.code == "401") {
                    //清除用户信息
                    window.sessionStorage.removeItem("userCode");
                    console.log("重连开始");
                    rs = await checkTokenExp(config, 0);
                    console.log("重连结束");
                }
                //success处理
                if (success) success(rs ? rs.data : null);
                //complete处理
                if (complete) complete();
                resolve(rs ? rs.data : null);

            }
        ).catch(
            (err) => {
                let msg = { code: "500", desc: "读取接口出错：" + err.message }
                //fail处理
                if (fail) fail(msg);
                //complete处理
                if (complete) complete();
                resolve(msg)
            });
    });
}
// 检查token是否过期
async function checkTokenExp(config, reload) {
    //重连2分钟
    if (reload < 5000) {
        // console.log("重连" + reload + "秒");
        var rs = await new Promise((resolve, reject) => {
            setTimeout(async () => {
                if (core.getUserInfo("id")) {
                    resolve({ data: await request(config) })
                } else {
                    resolve(await checkTokenExp(config, reload + 1));
                }

            }, 1000);
        });
        return rs;
    } else {
        return {data:null};
    }
}
// axios默认配置
//axios.defaults.timeout = 100000; // 超时时间

axios.interceptors.request.use(
    (config) => {
        if (process.env.VUE_APP_FLAG != "test") {
            var target = config.url.substring(0, config.url.indexOf("/", 1));
            if (target.indexOf("/") == 0 && target != null && target != "") {
                var domain = proxy[target];
                domain = domain != null ? domain.target : null;
                if (domain != null) config.url = domain + config.url.substring(config.url.indexOf("/", 1));
            }
        }
        if (config.headers.other == "noSend") {
            delete config.headers.other;
            delete config.headers.Authorization;
            delete config.headers.branchId;
            delete config.headers.functionId;
        } else {
            let token = core.getTokenInfo();
            if (token.accessToken != null) {
                config.headers.Authorization = "Bearer " + token.accessToken;
            }
            let branch = core.getBranchInfo();
            if (branch.id) {
                config.headers.branchId = branch.id;
            }
            let functionId = core.getFunctionId();
            if (functionId) {
                config.headers.functionId = functionId;
            }
        }
        if (config.method == "get") config.data = true;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(response => {
    // 拦截响应，统一处理
    try {
        if (response && response.data && response.data.code) {
            switch (response.data.code) {
                // 如果是登录过期或者是被人踢下线
                case 401:
                case "401":
                    // 先中断除了获取验证码的请求
                    removePending()
                    // cancelList = []
                    var loginInfo = { login: false, right: false, msg: response.data.desc || "登录过期，请重新登录！", user: {} };
                    top.vue.portalCheckRight(loginInfo);

                    break;
                //接口无权限访问
                case 403:
                case "403":
                    // 先中断除了获取验证码的请求
                    removePending()
                    // cancelList = []
                    var loginInfo = { login: true, right: false, msg: response.data.desc || "你没有权限访问该模块功能！", user: {} };
                    top.vue.portalCheckRight(loginInfo);
                    break;
            }
            return response
        } else {
            return response
        }
    } catch (err) {
        return Promise.reject(err)
    }

}, err => {
    if (err.response.status == 401) {
        var loginInfo = { login: false, right: false, msg: "未登录或登录过期，请重新登录！", user: {} };
        top.vue.portalCheckRight(loginInfo);
    } else if (err.response.status == 403) {
        var loginInfo = { login: false, right: false, msg: "你没有权限访问该模块功能！", user: {} };
        top.vue.portalCheckRight(loginInfo);
    }
    return err.response;
})
