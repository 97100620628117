<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
  
      <FormItem label="标题" prop="title">
        <Input type="text" v-model="formItems.title" placeholder="100字以内"></Input>
      </FormItem>
      <FormItem label="分类/标签" prop="label">
        <Select v-model="formItems.label" style="width: 300px" clearable filterable allow-create @on-create="handleCreate"
          placeholder="请选择，可输入并创建新标签。">
          <Option v-for="item in labelList" :value="item" :key="item">{{ item }}</Option>
        </Select>
        <span style="margin-left: 10px;">输入新标签（8个字内）后，按下回车键即可新建标签。</span>
      </FormItem>
      <FormItem label="剧集数" prop="episodeTotal">
        <Input type="number" v-model.number="formItems.episodeTotal" style="width:200px"></Input>
      </FormItem>
      <FormItem label="从第几集开始购买" prop="buyStart">
        <Input type="number" v-model.number="formItems.buyStart" style="width:200px" placeholder="0为免费"></Input>
      </FormItem>
      <FormItem label="价格/扣积分" prop="price" v-if="formItems.buyStart>0">
        <Input type="number" v-model.number="formItems.price" style="width:200px"></Input>
      </FormItem>
  
      <FormItem label="图片标题" prop="poster">
        <FNCUpload type="image" style="float:left" :showView="true" :defaultList="formItems.poster" :showDel="true"
          @success="upLogoImage">
        </FNCUpload>
      </FormItem>
      <FormItem label="简介" prop="info">
        <Input type="textarea" v-model="formItems.info" placeholder="200字以内"></Input>
      </FormItem>
      <FormItem label="启禁用" prop="idisplay">
        <RadioGroup v-model="formItems.idisplay">
          <Radio :label="1">启用</Radio>
          <Radio :label="0">禁用</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="视频列表" prop="poster">
        <FNCUpload type="file" style="width:100%;" :multiple="true" :showView="true" :fileLength="1000" :hashMaxSize="100"
          accept="video/*" :defaultList="shortvideoDetail" :showDel="true" @success="uVideo">
        </FNCUpload>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCUpload from '@/components/FNCUpload';
import FNCMapCoordinatePickup from '@/components/FNCMapCoordinatePickup';

export default {
  components: {
    FNCUpload, FNCMapCoordinatePickup
  },
  props: {

  },

  data() {
    return {
      pictureList: [],
      formItemsDefault: {
        id: 0,
        title: "",
        poster: "",
        label: "",
        episodeTotal: "",
        info: '',
        buyStart: 0,
        price: 0,
        idisplay: 1,
        shortvideoDetail: "",

      },
      formItems: {},
      ruleItems: {
        episodeTotal: [
          { type: "number", required: true, message: '集数不能小于0', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
      },
      labelList: [],
      shortvideoDetail: [],
    };
  },
  mounted() {

  },
  methods: {

    async loadData(id) {
      this.formItems = JSON.parse(JSON.stringify(this.formItemsDefault));
      this.shortvideoDetail = [];
      await this.loadLabelList();
      if (id) {
        this.$Message.loading({ content: "读取中...", duration: 0 })
        this.$get({
          url: "/shortvideo/api/shortvideo/view",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              if (this.formItems.shortvideoDetail) this.shortvideoDetail = this.formItems.shortvideoDetail;
              this.formItems.shortvideoDetail="";
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          },complete:()=>{
            this.$Message.destroy()
          },
        });
      }
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if(this.shortvideoDetail&&this.shortvideoDetail.length>0){
            var detail=[];
           this.shortvideoDetail.map(item => {
            let item1={};
            item1.videoName = item.name;
            item1.videoSize = item.size;
            item1.poster = item.url + ".jpg";
            item1.videoUrl = item.url;
            detail.push(item1);
          })
          this.formItems.shortvideoDetail = JSON.stringify( detail);
        }
          callback(true)
          this.$post({
            url: "/shortvideo/api/shortvideo/save",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    upLogoImage(files) {
      this.formItems.poster = files.url;
    },

    uVideo(files) {
      this.shortvideoDetail = files || [];
    },

    handleCreate(e) {
      if (this.labelList.find(item => item == e) == null)
        this.labelList.push(e);
    },
    async loadLabelList() {
      await this.$get({
        url: "/shortvideo/api/shortvideo/labellist",
        success: res => {
          if (res.code == "200")
            this.labelList = res.dataList || [];
        }
      });
    },
  }
};
</script>
    
<style scoped lang='less'></style>