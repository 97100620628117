<template>
  <Modal :mask-closable="mask" :closable="toggle === true ? false : true" footer-hide v-model="modal" :title="title"
    :width="width" :fullscreen="fullscreen" class-name="vertical-center-modal">
    <div id="title">
      <slot name="title"></slot>
    </div>
    <div :id="id"
      :style="'max-height:calc(90vh - ' + toolheight + ');height:calc(' + (fullscreen ? '100%' : height) + ' - ' + toolheight + ');overflow:auto;width:100%;'">
      <div :style="'margin:'+margin+'px;width:calc(100% - '+2*margin+'px);'">
        <!--内容区-->
        <slot name="contentarea">
        </slot>
      </div>
    </div>
    <!--工具条-->
    <div id="modaltoolbar" ref="modaltoolbar" :class="fullscreen ? '' : 'toolradius'">
      <slot name="toolsbar">
      </slot>
    </div>
  </Modal>
</template>

<script>
/**
 * 父级使用showModal()和hideModal()调用弹窗
 */
export default {
  props: {
    title: String,
    width: String,
    height: String,
    fullscreen: Boolean,
    margin:{type:Number,default:10},
    mask:{type:Boolean,default:false},
    id: { //ID
      type: String,
      default: "modal_contentarea"
    },
    toggle: {
      type: Boolean
    }
  },
  data() {
    return {
      modal: false,
      toolheight: "52px",
    };
  },
  created() {

  },
  mounted() {
    // var bgcolor=parent.vue.currentSystem.headerBgcolor||"#ddd";
    //  var ftcolor=parent.vue.currentSystem.headerFontcolor||"#222";
    //   this.$refs.modaltoolbar.style.backgroundColor=bgcolor;
    //   this.$refs.modaltoolbar.style.color=ftcolor;
    if (this.$refs.modaltoolbar.innerHTML == "") {
      this.$refs.modaltoolbar.style.display = "none";
      this.toolheight = "2px";
    }
  },
  watch: {
    modal: {
      handler(n) {
        if (n == false) {
          this.hideModal()
        }
      },
      immediate: true
    }
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    hideModal() {
      if (this.modal == true) {
        this.modal = false;
      } else {
        this.$emit('close', false);
      }
    },
  },
};
</script>

<style lang="less">
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }

  .ivu-modal-body {
    padding: 0px;
    font-size: 14px;
    line-height: 1.5;
  }


}

.ivu-modal-body {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5;
}

#modaltoolbar {
  min-height: 50px;
  background: #eee;
  display: flex;
  width: 100%;
  z-index: 2;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  border-top: #ddd solid 1px;
}

.toolradius {
  border-radius: 0 0 6px 6px;
}
</style>
