import { decrypt, encrypt } from "../utils/decrypt"
import { convertTDTToWX,convertWXToTDT,convertBDToTdt, convertBDToWX, convertWXToBD, convertTiandituToBD, createTiandituMap } from "../utils/map"
const matchs = { o: 1, g: 2, q: 3, h: 4, s: 5, n: 6, u: 7, z: 8, w: 9, x: 0 };
const core = {
  decrypt: decrypt,
  encrypt: encrypt,
  // 生成随机数字
  randomNumber(e) {
    e = e || 9;
    var t = '0123456789',
      a = t.length,
      n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
  },
  // 生成随机字符串
  randomString(e) {
    e = e || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
      a = t.length,
      n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
  },
  // 获取url指定的值
  getUrlParam: function (data) {
    return decodeURIComponent((new RegExp('[?|&]' + data + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
  },
  //格式化时间日期
  // 将 Date 转化为指定格式的String
  // 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
  // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
  formatDate: function (date, fmt) {
    try {
      var o = {
        "M+": date.getMonth() + 1, //月份 
        "d+": date.getDate(), //日 
        "H+": date.getHours(), //小时 
        "m+": date.getMinutes(), //分 
        "s+": date.getSeconds(), //秒 
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
        "S": date.getMilliseconds() //毫秒 
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    } catch (e) {
      return date;
    }
  },

 //将数字格式化时间日期
 formatNumberToDate: function (date, fmt) {
  return this.formatDate(this.convertDate(date), fmt);
},
//将字符串格式化时间日期
formatStringToDate: function (date, fmt) {
  if(!date) return "";
  return this.formatDate(this.convertDate(date), fmt);
},

//转化时间格式，兼容IOS等
convertDate: function (date) {
  var newDate = new Date(0);
  try {
    if (typeof (date) == 'number') {
      newDate = new Date(date);
    } else {
      if (date) newDate = new Date(date.replace(/-/g, "/"));
    }
  } catch (e) { }
  return newDate;
},

  //导出excel
  exportExcel: function (tHeader, filterVal, tableData, fileName) {
    require.ensure([], () => {
      const { export_json_to_excel } = require("@/utils/Export2Excel");
      const data = function formatJson() {
        return tableData.map((v) => filterVal.map((j) => v[j]));
      }();
      export_json_to_excel(tHeader, data, fileName); //导出Excel 文件名
    })
  },

  //处理空值
  ifNULLtoDF: function (str, df_back) {
    if (str == null || str == undefined || str == "" || str == "null" || str == "undefined") {
      return df_back;
    } else {
      return str;
    }
  },

  // 数组对象根据特定属性过滤
  onlyList: function (arr, ...argus) {
    return arr.reduce((pre, cur) => {
      let condition = '';
      argus.forEach((item, index) => {
        if (index == 0) {
          condition = 'e["' + item + '"]' + '===cur["' + item + '"]';
        } else {
          condition += '&&' + 'e["' + item + '"]' + '===cur["' + item + '"]';
        }
      })
      if (pre.find(e => eval(condition))) {
        return pre;
      } else {
        return [...pre, cur]
      }
    }, [])
  },

  //颜色取反
  ColorReverse(OldColorValue) {
    var OldColorValue = "0x" + OldColorValue.replace(/#/g, "");
    var str = "000000" + (0xffffff - OldColorValue).toString(16);
    return "#" + str.substring(str.length - 6, str.length);
  },

  //判断颜色深浅
  ColorIsLight(color) {
    // 16进制颜色值的正则
    var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    // 把颜色值变成小写
    color = (color||"").toLowerCase();
    if (reg.test(color)) {
      // 如果只有三位的值，需变成六位，如：#fff => #ffffff
      if (color.length === 4) {
        var colorNew = "#";
        for (var i = 1; i < 4; i += 1) {
          colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
        }
        color = colorNew;
      }
      // 处理六位的颜色值，转为RGB
      var colorChange = [];
      for (var i = 1; i < 7; i += 2) {
        colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
      }
      return (

        0.213 * colorChange[0] +

        0.715 * colorChange[1] +

        0.072 * colorChange[2] >

        255 * 2 / 3

      );
    } else {
      return false;
    }
  },
  sequence: 1,
  //生成ID顺序名称
  createID(DF) {
    this.sequence++;
    return (DF || "FNCView_") + this.sequence;
  },

  // 将blob转为base64,传进来的需要是一个包含type和size的blob对象
  // 直接获取返回值会获取一个到promise返回值，建议用async await获取
  blobToBase64: function (blob) {
    return new Promise((resolve, reject) => {
      // 新建一个fileReader对象，
      var reader = new FileReader()
      // readerAsDataURL获取fileReader对象的base64编码
      reader.readAsDataURL(blob)
      // 获取成功之后会执行onload函数，e.target.result的值是base64编码的值
      reader.onload = (e) => {
        resolve(e)
      }
      reader.onerror = (err) => {
        reject(err)
      }
    })
  },

  // 将base64编码转为file对象
  // 需要传入一个base64编码，以及传入文件名称
  dataURLtoFile: function (dataurl, filename, type) {
    // 首先获取到base64编码
    var arr = dataurl.split(',')
    // 将base64编码转化为字符串
    var bstr = atob(arr[1])
    // 拿到字符串的长度
    var n = bstr.length
    // 创建初始化为0，包含length个元素的无符号整数型数组
    var u8arr = new Uint8Array(n)
    // 对长度进行递减处理
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    // 最后将file对象return出去
    return new File([u8arr], filename ? filename : '默认文件名称', { type: type ? type : '' })
  },

  // 将url地址转为base64
  // 需要传入一个url网络地址，如http://xxx.png,以及type如image/png,image/jpeg
  urlToBase64: function (url, type) {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        // 将图片插入画布并开始绘制2d
        canvas.getContext('2d').drawImage(image, 0, 0)
        // 获取到base64编码，
        let result = canvas.toDataURL(type)
        resolve(result)
      }
      image.setAttribute('crossOrigin', 'anonymous'); // 解决跨域
      image.crossOrigin = '*';
      image.src = url + '?v=' + Math.random();  // 解决图片URL缓存问题
      // 图片加载失败的处理
      image.onerror = (err) => {
        reject(err)
      }
    })
  },

  // 将file转blob
  // 需要传入一个file对象和type类型如："image/png"或者"image/jpeg"
  fileChangeBlob: function (file, type) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      let rs = reader.readAsArrayBuffer(file)
      let blob = null
      reader.onload = (e) => {
        if (typeof e.target.result === 'object') {
          blob = new Blob([e.target.result], { type: type ? type : '' })
        } else {
          blob = e.target.result
        }
        resolve(blob)
      }
      reader.onerror = (err) => {
        reject(e)
      }
    })
  },
  // file转base64方法
  getBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      let fileResult = "";
      reader.readAsDataURL(file);
      reader.onload = () => {
        fileResult = reader.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.onloadend = () => {
        resolve(fileResult);
      };
    });
  },

  // 格式化table表格：用于显示合并表单专用
  handleMergeRow: function (data) {
    let ret = JSON.parse(JSON.stringify(data));
    let collection = {};
    let preIndex = -1;
    for (let i in ret) {
      // item = {
      //     "name": "Jim",
      //     "age": 18,
      //     "address": "Sydney",
      //     "date": "2016-10-03"
      // }
      let item = ret[i];
      item.source = {};
      for (let key in item) {
        if (key == "source") {
          continue;
        }
        // 记录每个key
        // collection = {
        //     "name": {}
        // }
        if (!collection[key]) {
          collection[key] = {};
        }
        // 首项或者该项此列不等于上一项此列
        if (preIndex == -1 || ret[preIndex][key] != item[key]) {
          // 记录每个key对应的所有值以及其起始索引和出现次数
          // collection = {
          //     "name": {
          //         "Jim#0": {
          //             "firstIndex": 0,
          //             "count": 1
          //         }
          //     }
          // }
          collection[key][`${item[key]}#${i}`] = {
            firstIndex: i,
            count: 1
          };
          // 标注该列的起源index
          item.source[key] = i;
        } else {
          // 通过上一项找到起源index
          let source = ret[preIndex].source[key];
          item.source[key] = source;
          // 合并该列的行数加1
          collection[key][`${item[key]}#${source}`].count += 1;
        }
      }
      preIndex = i;
    }
    for (let key in collection) {
      // items = {
      //     "Jim#0": {
      //         "firstIndex": "0",
      //         "count": 3
      //     },
      //     "Jon#3": {
      //         "firstIndex": "3",
      //         "count": 1
      //     }
      // }
      let items = collection[key];
      for (let itemkey in items) {
        // value = {
        //     "firstIndex": "0",
        //     "count": 3
        // }
        let value = items[itemkey];
        // 根据记录的起始索引设置该项的mergeRow
        // {
        //     "name": "Jim",
        //     "age": 18,
        //     "address": "Sydney",
        //     "date": "2016-10-03",
        //     "mergeRow": {
        //         "address": 1,
        //         "age": 1,
        //         "date": 1,
        //         "name": 3
        //     }
        // }
        if (!ret[value.firstIndex].mergeRow) {
          ret[value.firstIndex].mergeRow = {};
        }
        ret[value.firstIndex].mergeRow[key] = value.count;
      }
    }
    return ret;
  },
  // 打开layer-ui弹窗
  /**
   * 参数说明
   * type: 0（信息框，默认）, 1 (页面层) , 3（加载层），4、（tips层）， 打开路由文件需要使用 1
   * title:标题
   * area：[100%,100%] // 全屏显示
   * content：需要传入的跳转链接地址，或者是文本内容
   * 
   * 详细参数参考地址：http://www.uimaker.com/layui/doc/modules/layer.html#area
   * */
  openLayerFrame: function (data, cancelCallback, successCallback) {
    parent.layer.open({
      area: ['100%', '100%'],
      ...data,
      cancel: () => {
        typeof (cancelCallback) === 'function' && cancelCallback()
      },
      success: () => {
        typeof (successCallback) === 'function' && successCallback()
      }
    });
  },


  // 封装防抖函数，点击多次，只会执行最后一次
  /**
   * fn：可以执行的回调函数，可以在里边传你想要运行的代码
   * timer: 当前页面传进来的data里边的参数，必传，类型为字符串，比如this.timer传的参数为 'timer'
   * wait: 防抖时间，点击多次之后多少时间执行，默认1000ms
  */
  debounce(fn, timer, wait = 1000) {
    var _this = this;
    if (this[timer]) clearTimeout(this[timer]);
    this[timer] = setTimeout(() => {
      fn.apply(_this);
    }, wait);
  },

  // 信息类同意跳转的方法
  funToPage: function (todo) {
    if (todo && todo.functionType) {
      if (todo.functionType == "1") {
        // 图文
        content = `/graphiccategory`
      } else if (todo.functionType == "2") {
        // 基本信息
        content = `/houseingarrairsindex`
      } else if (todo.functionType == "3") {
        // 音视频
        content = `/newhousingaffairs`
      } else if (todo.functionType == "5") {
        // 地图
        content = `/mapclass`
      } else if (todo.functionType == "15") {
        content = `/liveroommanage`
      } else if (todo.functionType == "16") {
        content = `/voteindex`
      } else if (todo.functionType == "17") {
        // 答题活动
        content = `/answerindex`
      } else if (todo.functionType == "24") {
        // 预约活动
        content = `/activityindex`
      } else if (todo.functionType == "25") {
        // 问卷活动
        content = `/questionnaireindex`
      } else {
        if (todo.appCode == 'suiyue') {
          content = '/#' + todo.url
        } else {
          content = todo.url
        }
        var a = document.createElement("a")
        a.href = content
        a.click()
        return

      }
      return content += `?menuId=${todo.menuCode}`
    }
  },

  // 根据接口导出数据
  exportGetExcel: function (url, data, that, type) {
    const { get, post } = require('../api/index')
    that.$Modal.confirm({
      title: "温馨提示",
      content: "确认导出当前列表信息",
      onOk: async () => {
        that.$Message.loading({
          content: "正在导出，请稍等...",
          duration: 0,
        });
        if (type == 'get' || type == 'GET' || type == 'Get' || !type || type == '') {
          var res = await get(url, { ...data })
        } else if (type == 'post' || type == 'POST' || type == 'Post') {
          var res = await post(url, { ...data })
        }
        if (res.code == 200) {
          if (res.data) {
            if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
              res.data = res.data.replace("http", "https")
            }
          }
          let link = document.createElement("a")
          link.href = res.data
          document.body.appendChild(link)
          link.click()
          link.download = "导出信息"
          document.body.removeChild(link)
          that.$Message.destroy()
        } else {
          that.$Message.warning({
            content: res.desc,
            background: true,
          })
        }

      },
    })
  },
  // 树形结构，知道某一节点，一次向上获取所有父节点
  treeFindPath: function (tree, func, code, name, path = []) {
    if (!tree) return []
    for (const data of tree) {
      // 存放最后返回的内容
      path.push({
        code: data[code],
        name: data[name]
      })
      if (func(data)) return path
      if (data.children) {
        const findChildren = this.treeFindPath(data.children, func, code, name, path)
        if (findChildren.length) return findChildren
      }
      path.pop()
    }
    return []
  },

  // hex转字符串,16进制ASCII
  hexToString: function (data) {
    if (data == null) return null;
    if (data.length % 2) return '';
    for (var key in matchs) {
      data = data.replace(new RegExp(key, 'g'), matchs[key]);
    }
    var tmp = '';
    for (var i = 0; i < data.length; i += 2) {
      tmp += '%' + data.charAt(i) + data.charAt(i + 1);
    }
    try {
      return decodeURIComponent(tmp);
    } catch (e) {
      return null;
    }

  },
  // 字符串转hex
  stringToHex: function (str) {
    if (str == null) return null;
    var re = /[\u4E00-\u9FA5！￥（）——、】【‘；：“’”《》？]/;
    var ar = [];
    for (var i = 0; i < str.length; i++) {
      var a = '';
      if (re.test(str.charAt(i))) { // 中文
        a = encodeURIComponent(str.charAt(i)).replace(/%/g, '');
      } else {
        a = str.charCodeAt(i).toString(16);
      }
      ar.push(a);
    }
    str = ar.join("");
    for (var key in matchs) {
      str = str.replace(new RegExp(matchs[key], 'g'), key);
    }
    return str;
  },

  // hex转json,16进制ASCII
  hexToJson: function (data) {
    var str = this.hexToString(data);
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  },
  // json转hex
  jsonToHex: function (str) {
    try {
      var data = JSON.stringify(str);
      return this.stringToHex(data);
    } catch (e) {
      return null;
    }
  },

  //获取平台配置信息：key不指定则输出所有参数
  getPortalConfig(key) {
    var pc = {}
    var pcStr = window.localStorage.getItem("portalCode")
    if (pcStr) {
      try {
        pc = JSON.parse(this.decrypt(pcStr))
      } catch (e) { }
      if (pc && pc.bgimages) {
        var bgimages = []
        try {
          bgimages = JSON.parse(pc.bgimages)
        } catch (e) { }
        pc.bgimages = bgimages
      }
      if (pc && pc.bgimages && pc.bgimages.length == 0) {
        pc.bgimages = ["./images/bg0.jpg", "./images/bg1.jpg"]
      }
    }
    pc = pc || {}
    if (key) {
      return pc[key]
    } else {
      return pc
    }
  },
  //获取Token配置信息：key不指定则输出所有参数
  getTokenInfo(key) {
    var tokenInfo = top.tokenInfo;
    if (!tokenInfo) {
      var userStr = window.sessionStorage.getItem("userCode")
      if (userStr) {
        try {
          var user = JSON.parse(this.decrypt(userStr))
          if (user) {
            tokenInfo = { accessToken: user.accessToken, expiresIn: parseFloat(user.expiresIn || 0), expiration: parseFloat(user.expiration || 0), scope: user.scope, tokenType: user.tokenType, refreshToken: user.refreshToken }
            top.tokenInfo = tokenInfo
          }
        } catch (e) { }
      }
    }
    tokenInfo = tokenInfo || {}
    if (key) {
      return tokenInfo[key];
    } else {
      return tokenInfo
    }
  },
  //获取商户配置信息：key不指定则输出所有参数
  getBranchInfo(key) {
    var current = {}
    var currentSystem = window.sessionStorage.getItem("currentSystem")
    if (currentSystem) {
      try {
        current = JSON.parse(currentSystem)
      } catch (e) { }
    }
    current = current || {}
    if (key) {
      return current[key]
    } else {
      return current
    }

  },
  //获取用户信息：key不指定则输出所有参数
  getUserInfo(key) {
    var userInfo = {}
    var userStr = window.sessionStorage.getItem("userCode")
    if (userStr) {
      try {
        var user = JSON.parse(this.decrypt(userStr))
        if (user) {
          userInfo = user.user
        }
      } catch (e) { }
    }
    userInfo = userInfo || {}
    if (key) {
      return userInfo[key];
    } else {
      return userInfo
    }
  },
  /**
   * 获取商户应用的额外用户信息
   *
   * @param key 若有值则输入对应值，否则输出所有
   * @return
   */
  getBranchUserInfo(key) {
    var res = null;
    try {
      var user = this.getUserInfo();
      var branchId = this.getBranchInfo("id");
      var branchUser = null;
      if (user != null && branchId) {
        var busers = user.branchUser;
        if (busers) {
          busers.map(buser => {
            if (buser && buser.id && buser.id == branchId) {
              branchUser = buser;
              return;
            }
          })
        }
      }
      if (key != null) {
        if (branchUser && branchUser[key]) {
          res = branchUser[key];
        }
      } else {
        res = branchUser;
      }
    } catch (e) { }
    return res;
  },
  //获取用户绑定的公司/部门
  getDeptIds(){
    var depts=(this.getBranchUserInfo("deptid")  || "").split(",")||[];
    var deptid=[];
    //去从属
    for(var i=0;i<depts.length;i++){
        var dept=deptid.find(item=>item.indexOf(depts[i])==0||depts[i].indexOf(item)==0);
        if(dept){
          if(dept.length>depts[i].length){
            deptid.splice(deptid.indexOf(dept),1,depts[i])
          }
        }else{
          deptid.push(depts[i])
        }
    }
    return deptid;
  },
  getFunctionId() {
    return this.getUrlParam("functionid") || this.getUrlParam("functionId");
  },
  /**
 * 检测并控制操作按钮的权限
 * @param buttons按钮列表 [{btn:$("#add"),right:"A"}]，其中，btn为按钮对象，right是权限值，后台定义的各权限：
 * R：访问权限
 * A：增加权限
 * E：修改权限
 * D：删除权限
 * P：审核权限
 * S：查询权限
 * @param funcid指定的栏目，为空则自动获取本栏目
 * 例：
 * 	this.$core.checkControlButtonRight([
                           {btn:$("#add"),right:"A"},
                           {btn:$("#edit"),right:"E"},
                           {btn:$("#del"),right:"D"}
                           ]);//检测用户操作权限
 */
  checkControlButtonRight(buttons, funcid) {
    return new Promise(async (resolve) => {
      var map = await this.getControlRight(null, funcid);
      if (map != null) {
        for (var i = 0; i < buttons.length; i++) {
          if (map[buttons[i].right]) {
            buttons[i].btn.fadeIn();
          } else {
            buttons[i].btn.fadeOut(function () {
              $(this).remove()
            });
          }
        }
      }
      resolve(map)
    });
  },
  /**
  * 获取某个操作权限
  * @param right操作权限，不指定则返回所有
  * R：访问权限
  * A：增加权限
  * E：修改权限
  * D：删除权限
  * P：审核权限
  * S：查询权限
  * @param funcid 
  * @returns 
  * 例：getControlRight("A");
  */
  async getControlRight(right, funcid) {
    if (funcid == null) {
      funcid = this.getFunctionId();
    }
    var map = await top.vue.$get({ url: "/gateway/api/manage/appcenter/right/" + funcid });
    if (map != null) {
      map.A = map.a;
      map.D = map.d;
      map.E = map.e;
      map.P = map.p;
      map.R = map.r;
      map.S = map.s;
      if (right != null) {
        return right.split(",").some(item => map[item] == true);
      } else {
        return map;
      }
    } else {
      return {};
    }
  },
  /**
 * 通过参数类型代码styleen获取枚举表的集合，df是否带默认值，dfKey默认值名
 * AppCenter.java
 */
  async getStyleParamList(styleen, df, dfKey, callback) {
    var res = await top.vue.$get({ url: "/gateway/api/manage/appcenter/styleparam/list", data: { styleen: styleen, df: df, dfKey: dfKey } });
    if (callback) callback(res);
    return res;
  },
  /**
   * 通过参数类型代码styleen和参数代码styleid获取outcolumnName字段的内容,默认stylename，多个id用,隔开
   * AppCenter.java
   */
  async getStyleParam(styleen, styleid, outcolumnName, callback) {
    outcolumnName2 = (outcolumnName == null || outcolumnName == undefined || outcolumnName == "") ? "stylename" : outcolumnName;
    var res = await top.vue.$get({ url: "/gateway/api/manage/appcenter/styleparam/name", data: { styleen: styleen, styleid: styleid, outcolumnName: outcolumnName2 } });
    if (callback) callback(res);
    return res;
  },
  /**获取地区集合
   * 适合Cascader 级联选择
   *  parentid: 哪一级的父级ID
   * areaCodePrefix: 代码前缀，例如，广东省（440000）及以下的地区，前缀为：44
   */
  async getAreaList(parentid, areaCodePrefix, callback) {
    var findArea = (pid, areas) => {
      var children = []
      areas.map((item) => {
        if (item.parentid == pid) {
          var child = item
          child.value = item.id
          child.label = item.name
          let c = findArea(item.id, areas);
          if (c && c.length > 0) {
            child.children = findArea(item.id, areas);
          }
          children.push(child)
        }
      })
      return children;
    }
    var areasList = [];
    await top.vue.$get({
      url: "/gateway/api/manage/appcenter/area/alllist", data: { areaCodePrefix: areaCodePrefix }, success: (res) => {
        if (res.code == "200" && res.dataList) {
          var areas = res.dataList
          areasList = findArea(parentid, areas)
          if (callback) callback(areasList);
        }
      }
    })
    return areasList;
  },

  /**
* @Description 天地图转腾讯(高德)地图
* @Param lat, lng
* @return {}
**/
convertTDTToWX(lat, lng) {
  return convertTDTToWX(lat, lng);
},

/**
* @Description 腾讯(高德)地图转天地图
* @Param lat, lng
* @return {}
**/
convertWXToTDT(lat, lng) {
  return convertWXToTDT(lat, lng);
},

  /**
   * @Description 百度地图转腾讯(高德)地图
   * @Param lat, lng
   * @return {}
   **/
  convertBDToWX(lat, lng) {
    return convertBDToWX(lat, lng);
  },
  /**
   * @Description 腾讯(高德)地图转百度地图
   * @Param lat, lng
   * @return {}
   **/
  convertWXToBD(lat, lng) {
    return convertWXToBD(lat, lng);
  },
  /**
     * @Description 天地图(google、GPS模块)地图转百度地图
     * 政府官方免费，天地图：http://lbs.tianditu.gov.cn/home.html
   * @Param lat, lng
   * @return {}
     * */
  convertTiandituToBD(lat, lng) {
    return convertTiandituToBD(lat, lng);
  },


  /**
    * @Description 百度地图转天地图(google、GPS模块)地图
    * @Param lat, lng
    * @return {}
  * */
  convertBDToTianditu(lat, lng) {
    return convertBDToTdt(lat, lng);
  },

  /**
    * @Description 天地图地图加载，适用H5和web端
  * */
  async createTiandituMap(mapDiv, ak, center, zoom) {
    return await createTiandituMap(mapDiv, ak, center, zoom);
  },
  /**
   * 取头尾匹配的值
   * 例：取以${开头，以}结尾的所有值
   * ios上不支持正则的零宽断言的解决方法
   */
  regExpMatch(str, start, end) {
    var v = [];
    if (str && start && end) {
      var li = str.indexOf(start);
      if (li > -1) {
        var rStr = str.substring(li + start.length);
        var ri = rStr.indexOf(end);
        if (ri > -1) {
          var tStr = rStr.substring(0, ri);
          v.push(tStr)
          var lStr = rStr.substring(ri + end.length);
          if (lStr) {
            var oV = this.regExpMatch(lStr, start, end);
            v = v.concat(oV)
          }
        }
      }
    }
    return v;
  },
}
export default core