<template>
    <div class="fnc-area-select-list">
        <Select
            :id="'selector' + idx"
            transfer
            v-model="value[idx]"
            class="select-list-size"
            placeholder="请选择"
            @on-change="
                e => {
                    changeArea(idx, e)
                }
            "
            v-for="(list, idx) in arealist"
            :key="'list-' + idx"
            v-show="!isView"
        >
            <Option :value="item.id" v-for="(item, index) in arealist[idx]" :key="'area-' + idx + '-' + index">{{ item.name }}</Option>
        </Select>
        <span v-if="isView">{{ text }}</span>
    </div>
</template>
<script>
/**
 * import FNCAreaSelectList from '@/components/FNCAreaSelectList';
 * 需要引入注解@onChange=""接收数据
 * <FNCAreaSelectList ref="areaSlt" @onChange="test"></FNCAreaSelectList>
 * this.$refs.areaSlt.initData(["440000","440100","440106"]);
 */
export default {
    props: {
        areaCodePrefix: {
            //地区代码的前缀，全部为""
            type: String,
            default: "",
        },
        parentid: {
            //哪一级的父级ID
            type: String,
            default: "100000",
        },
        column: {
            //多列
            type: Number,
            default: 3,
        },
        startParent: {
            //从那地区开始
            type: String,
            default: "",
        },
        isView: {
            //是否只显示区域文本
            type: Boolean,
            default: false,
        },
        separate: {
            //间隔符
            type: String,
            default: " > ",
        },
    },
    data() {
        return {
            value: [],
            areas: [],
            arealist: [],
            text: "",
        }
    },
    created() {
        this.loadBaseData()
    },
    mounted() {},
    methods: {
        initData(value) {
            if (!value || value.length < this.column) {
                value = value ? [...value] : []
                for (var i = value.length; i < this.column; i++) {
                    value.push("")
                }
            }
            value.length = this.column
            if (!value[0]) value[0] = this.startParent || ""
            this.value = value
            for (var i = 1; i < this.value.length; i++) {
                this.arealist[i] = this.findArea(this.value[i - 1]) || []
            }
            // 只显示文本
            if (this.isView == true) {
                this.$nextTick(() => {
                    var text = ""
                    for (var i = 0; i < this.value.length; i++) {
                        var t = this.$("#selector" + i).text().trim()
                        
                        if (t && t != "请选择") {
                            if (text) text += this.separate
                            text += t
                        }
                    }
                    this.text = text
                })
            }
        },
        /**
         * 获取选项数据
         */
        loadBaseData() {
            this.$get({
                url: "/gateway/api/manage/appcenter/area/alllist",
                data: { areaCodePrefix: this.areaCodePrefix },
                success: res => {
                    if (res.code == "200" && res.dataList) {
                        this.areas = res.dataList
                        this.arealist = []
                        for (var i = 0; i < this.column; i++) {
                            this.arealist.push([])
                        }
                        this.arealist[0] = this.findArea(this.parentid || "100000")
                    }
                },
            })
        },
        findArea(parentid) {
            var list = []
            if (this.areas) {
                var list = this.areas.filter(item => item.parentid == parentid) || []
            }
            return list
        },
        changeArea(index, e) {
            var value = this.value
            value[index] = e || ""
            for (var i = index + 1; i < value.length; i++) {
                value[i] = ""
            }
            this.initData(value)
            this.$emit("onChange", value)
        },
    },
}
</script>

<style lang="less" scoped>
.fnc-area-select-list {
    display: flex;

    .select-list-size {
        width: calc(33.33333% - 10px);
        margin-right: 10px;
    }
}
</style>
