<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <!-- <Select v-model="pageParams.status" style="width: 200px" placeholder="全部状态">
          <Option v-for="item in statusList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select> -->
      <div style="width:500px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入终端号(IMEI)" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>
      <!-- <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />添加
      </Button>

      <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <!-- <FNCModal ref="editModal" title="编辑窗" :fullscreen="false" width="800px" height="600px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal> -->
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/devicepush
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
// import Edit from "./childrens/Edit";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    // Edit,
  },

  data() {
    return {
      title: "信息列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        status: "",
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "终端号(IMEI)",
          key: "imei",
          align: "center",
          width: 180,
        },
        {
          title: "设备名称",
          key: "deviceName",
          align: "center",
          width: 180,
        },
        {
          title: "设备类型",
          key: "deviceType",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div",this.convertObject("deviceTypes","value","name",params.row.deviceType))},
        },
        {
          title: "消息状态",
          key: "status",
          align: "center",
          minWidth: 220,
          render: (h, params) => {
         
            return h("div", [
              h("span", {
              style: {
                color: params.row.warnStatus==1?'red':'lightgrey',
                margin:"0 5px",
              }
            }
              , "报警"
              ),
              h("span", {
              style: {
                color: params.row.dismantleStatus==1?'red':'lightgrey',
                margin:"0 5px",
              }
            }
              , "拆卸"
              ),
              h("span", {
              style: {
                color: params.row.lowbatteryStatus==1?'red':'lightgrey',
                margin:"0 5px",
              }
            }
              , "电池低压"
              ),
              h("span", {
              style: {
                color: params.row.faultStatus==1?'red':'lightgrey',
                margin:"0 5px",
              }
            }
              , "故障"
              ),
            ]
            )
       },
        },
        {
          title: "信号强度",
          key: "csq",
          align: "center",
          width: 70,
        },
        {
          title: "信噪比",
          key: "sinr",
          align: "center",
          width: 80,
        },
       
        {
          title: "烟雾浓度",
          key: "concentration",
          align: "center",
          width: 70,
        },
        {
          title: "温度",
          key: "temperature",
          align: "center",
          width: 70,
        },   {
          title: "湿度",
          key: "humidity",
          align: "center",
          width: 70,
        },
        {
          title: "电池电量",
          key: "batteryPower",
          align: "center",
          width: 70,
        }, {
          title: "软件版本",
          key: "softVersion",
          align: "center",
          width: 70,
        },
        {
          title: "硬件版本",
          key: "hardVersion",
          align: "center",
          width: 70,
        },
        {
          title: "推送时间",
          key: "pushTime",
          align: "center",
          width: 180,
        },
       
        // {
        //   title: "操作",
        //   slot: "action",
        //   width: 200,
        //   align: "center",
        //   render: (h, params) => {
        //     return h("div",
        //       [
        //         h(
        //           "Button",
        //           {

        //             class: "editDiv"
        //             ,
        //             props: {
        //               type: "primary",
        //               size: "small",
        //             },
        //             style: {
        //               margin: "0 5px",
        //             },

        //             on: {
        //               click: () => {
        //                 this.editData(params.row.id);
        //               },
        //             },
        //           },
        //           "去处理"
        //         ),
        //       ]);
        //   },
        // },
      ],
      right: {},
      saveLoading: false,
      statusList:[],
      deviceTypes:[],
    };
  },
  created() {
    this.imei=this.$core.getUrlParam("keyword");
    if(this.imei){
      this.pageParams.keyword=this.imei;
    }
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
     loadModuleInfo() {
      //读取栏目信息
       this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      this.$core.getStyleParamList("smartsmoke_deviceType", true, "全部类型", (res) => {
        this.deviceTypes = res || [{ name: "全部状态", value: "" }]
      })
      // this.$core.getStyleParamList("smartsmoke_devicepushStatus", true, "全部状态", (res) => {
      //   this.statusList = res || [{ name: "全部状态", value: "" }]
      // })
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartsmoke/devicepush/queryDevicepush",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = {...this.pageParams,...res.pageParams}
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },

    // deleteData(row) {
    //   if (this.right["D"] == false) {
    //     this.$Message.error({ background: true, content: "你没有删除权限" })
    //     return;
    //   }
    //   var ids = ""
    //   if (row) {
    //     ids = row.id
    //   } else {
    //     let selectedList = this.$refs.fncTable.getSelection();
    //     if (selectedList && selectedList.length > 0) {
    //       ids = selectedList.map(item => {
    //         return item.id;
    //       }).join(",")
    //     }
    //   }
    //   if (!ids) {
    //     this.$Message.error({ background: true, content: "并没有选择审核项" })
    //     return;
    //   }

    //   this.$Modal.confirm({
    //     title: '删除确认',
    //     content: row ? '您确认要删除“<span style="color:red"> ' + row.brandName + '</span>”吗？' : "确认要删除选中吗？",
    //     onOk: () => {
    //       this.$post({
    //         url: "/smartsmoke/brandinfo/delBrandinfo", data: { ids: ids },
    //         success: (res) => {
    //           if (res.code == "200") {
    //             this.$Message.success({ background: true, content: res.desc })
    //             this.loadData(1);
    //           } else {
    //             this.$Message.error({ background: true, content: res.desc })
    //           }
    //         }
    //       });
    //     }
    //   });
    // },

    // editData(id) {
    //   if (id && this.right["E"] == true || !id && this.right["A"] == true) {
    //     this.$refs.editModal.showModal();
    //     this.$refs.editContent.loadData(id);
    //   }
    // },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    },
    convertObject(target,inFeild,outFeild,value){
      var object=this[target].find(item=>value==item[inFeild])
      return object?object[outFeild]:"无"
    },
  }
};
</script>
    
<style scoped lang='less'></style>