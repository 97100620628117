import { get } from "../api";
/**
 * 组织结构的列表读取
 * 引入依赖：import {orgCascader} from "@/utils/orgCascader";
 * 创建： this.orgCascader=orgCascader(orgType);可多个，建议在 created()创建
 * 读取数据：this.orgCascader.loadData(callback); 
 * 数据应用：this.orgCascader.orgList
 */
export const orgCascader = (orgType) => {
    return {
        orgList: [],//列表
        orgType: orgType,//组织结构的类型代码
        parentOrgCode: null,
        /**
         * 初始化，读取列表
         * parentOrgCode:指定orgCode则只显示他所在层级
         */
        loadData(callback, parentOrgCode) {
            this.orgList = []
            if (parentOrgCode) {
                this.parentOrgCode = typeof parentOrgCode === "object" ? parentOrgCode : [parentOrgCode];
                this.parentOrgCode = this.parentOrgCode.length==0?null:this.parentOrgCode;
            }

            get({
                url: "/gateway/api/organization/app/list", data: { orgType: this.orgType }, success: (res) => {
                    if (res.code == "200" && res.dataList) {
                        if (this.parentOrgCode) {
                            this.orgList = [];
                            for(var i = 0; i < this.parentOrgCode.length; i++){
                                this.orgList = [...this.orgList,...this.convectRightCascader(res.dataList, this.parentOrgCode[i])];
                            }
                        } else {
                            this.orgList = res.dataList;
                        }
                    }
                }, complete: () => {
                    if (callback) callback(this.orgList);
                }
            })
        },
        convectRightCascader(orgList, orgCode) {
            var rightList = [];
            orgList && orgList.map((item) => {
                if (orgCode.indexOf(item.orgCode) == 0) {
                    var org = JSON.parse(JSON.stringify(item));
                    if (org.hasChild == 1 && org.orgCode != orgCode) {
                        org.children = this.convectRightCascader(org.children, orgCode);
                    }
                    rightList.push(org);
                }
            });
            return rightList;
        },
        //查询最后一个是否从属parentOrgCode，从属则输出值
        checkOrgRight(e) {
            if (!this.parentOrgCode) return e;
            if (e && e.length > 0) {
                var last = e[e.length - 1];
                if (last) {
                    var poc=this.parentOrgCode.find(item => last.indexOf(item) == 0);                    
                    return poc?e:[];
                } else {
                    return [];
                }
            } else {
                return e;
            }
        },
        //级联转换名称，orgCode必填
        //this.orgCascader.convectCascaderName(orgCode)
        convectCascaderName(orgCode, i, orgList, name) {
            orgList = orgList || this.orgList;
            name = name || "";
            i = i || 0;
            i = i + 3;
            if ((orgCode||"").length >= i) {
                let did = orgCode.substring(0, i);
                let org = orgList.find(item => item.orgCode == did);
                if (org && org.orgName) {
                    name = (name ? name + " / " : "") + org.orgName;
                    if (org.children && org.children.length > 0) {
                        name = this.convectCascaderName(orgCode, i, org.children, name)
                    }
                }
            }
            return name;
        },
        /**
         *转换为ORG名称
         * @param {*} orgCode 
         */
        convectOrgName(orgCode) {
            var orgName = this.convectCascaderName(orgCode);
            if (orgName) {
                orgName = orgName.split("/") || [];
                orgName = orgName.length > 0 ? orgName[orgName.length - 1] : "";
            }
            return orgName;
        },
        /**
         * 级联值转换选中项，orgCode必填
         * this.orgCascader.convectCascaderList(orgCode)
         *  <Cascader v-model="org" change-on-select clearable :data="orgList" v-width="100" />
         */
        convectCascaderList(orgCode, i, orgList) {
            orgList = orgList || [];
            i = i || 0;
            i = i + 3;
            if ((orgCode||"").length >= i) {
                orgList.push(orgCode.substring(0, i));
                if (orgCode.length >= i + 3) {
                    orgList = this.convectCascaderList(orgCode, i, orgList)
                }
            }
            return orgList;
        },
    }
}